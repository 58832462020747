import React from 'react'
import Logo from './logo'
import SocialLinks from './social'

class Header extends React.Component {
  componentDidMount() {
  }
  
  render () {
    return (
      <div className="l-wrapper">
        <div className="l-container u-horizontal u-space-between">
          <div className="l-half">
            <Logo />
            <SocialLinks />
          </div>
          <div className="l-half">
            <p className="print-only">
              <strong>Pietro Di Marzo</strong>
              <br />
              <strong>Born: </strong> 21/04/1986
              <br />
              <strong>Email: </strong> pietrodimarzo@gmail.com
              <br />
              <strong>Phone: </strong> +61401755253
              <br />
              <strong>Website: </strong> www.nerdskullz.com
            </p>
            <p>
              Born and raised in sunny Palermo, Sicily; my interest in the internet, the web and graphic design started when I was 14.
              <br />
              Years later, after fulffilling my dream of attending Europe's best design school, IED - Istituto Europeo di Design, I arrived in Australia.
              <br />
              I am currently based in Melbourne, Victoria, and I'm always looking for new challenges and experience.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Header