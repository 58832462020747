import React from 'react'
import Layout from '../components/layout'
import Header from '../components/homepage/header'
import ProjectList from '../components/homepage/project_list'
import AdditionalInfo from '../components/homepage/additional_info'

class Homepage extends React.Component {
  componentDidMount() {
  }
  
  render () {
    return (
      <Layout>
        <Header />
        <ProjectList />
        <AdditionalInfo />
      </Layout>
    )
  }
}

export default Homepage