import React from 'react'

class Logo extends React.Component {
  componentDidMount() {
  }
  
  render () {
    return (
      <>
        <h1 className="t-heading-1">
          Nerd<span>Skullz</span>
        </h1>
      </>
    )
  }
}

export default Logo