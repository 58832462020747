import React from 'react'

class AdditionalInfo extends React.Component {
  render () {
    return (
      <div className="l-wrapper">
        <div className="l-container u-horizontal u-space-between">
          <div className="l-half">
            <h2 className="t-heading-2">Why Nerd</h2>
            <p>
              I am completely devoted to new technologies, from desktop computers to mobile devices and programming structures. I have a strong interest towards graphic design and real-time interaction software such as Processing and Quartz Core for graphic design interaction.
              <br />
              I am also fond of interaction design and development with devices like Arduino, Wii controller, Kinect.
              <br />
              I love keeping myself up to date with what’s new about the IT world; I am fond of science fiction, tattoos and Harley-Davidson motorbikes.
            </p>
          </div>
          <div className="l-half">
            <h2 className="t-heading-2">Why Skullz</h2>
            <p>
              Skullz stands for Skills. My work and educational experience have provided my with a solid and varied background that can actually make a difference on the projects I deliver and the companies I work for: not only am I strongly confident with web development, but I am also well aware of the importance of combining the best marketing and user experience practices in order to develop a website that will generate conversions and leading big teams to achieve their goals.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default AdditionalInfo