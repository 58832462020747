import React from 'react'
import ProjectItem from './project_item'
import projectsData from '../../json/projects.json'

class ProjectsList extends React.Component {

  updateDimensions = () => {
    const el = document.getElementById('projects__list'),
          elReference = document.getElementById('projects__title');
    let translateValue = ((window.innerWidth - elReference.offsetWidth) / 2)

    el.style.transform = 'translateX(' + translateValue + 'px)';
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }
  
  render () {
    return (
      <>
        <div className="l-wrapper">
          <div id="projects__title" className="l-container">
            <h2 className="t-heading-2">Recent Projects</h2>
          </div>
        </div>
        <div className="l-wrapper l-wrapper--full">
          <div className="l-container l-container--full i-projects__container">
            <div className="i-projects__list-wrapper">
              <ul id="projects__list" className="i-projects__list">
                {projectsData.map((item, index) => (
                  <ProjectItem
                    key={index}
                    title={item.title}
                    description={item.description}
                    agency={item.agency}
                    duties={item.duties}
                    image={item.image}
                    technology={item.technology}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default ProjectsList